import * as React from "react"
import { graphql as gql, PageProps } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import useStoryblok from "../lib/storyblok"
import useStoryblokComponent from "../lib/useStoryblokComponent"

const IndexPage: React.FC<PageProps<any>> = ({ data, location }) => {
  const story = useStoryblok(data.storyblokEntry, location)

  const StoryblokComponents = useStoryblokComponent(story)

  return (
    <Layout>
      <Seo title="Home" />
      {StoryblokComponents}
    </Layout>
  )
}

export default IndexPage

export const query = gql`
  query Home {
    storyblokEntry(full_slug: { eq: "home" }) {
      name
      content
    }
  }
`
